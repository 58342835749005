.subnav-link {
    @include fs-textSans(1);
    color: $brightness-7;
    display: block;
    height: $pillar-height - $gs-baseline / 2;
    line-height: $pillar-height - $gs-baseline / 2;
    padding: 0 4px;
    position: relative;

    @include mq(mobileMedium) {
        font-size: 14px;
    }

    @include mq(tablet) {
        @include fs-textSans(5, true);
        height: $pillar-height;
        line-height: $pillar-height;
    }

    &:after {
        @include mq($until: tablet) {
            border-bottom: 1px solid $brightness-86;
            bottom: -1px;
            content: '';
            left: $gs-gutter / 4;
            position: absolute;
            right: -9999px;
        }
    }

    .subnav__item--parent & {
        display: inline-block;
        font-weight: 700;
    }

    &:hover,
    &:focus {
        color: $brightness-20;
    }
}

.subnav-link--toggle-more {
    background: transparent;
    border: 0;
    color: $brightness-46;

    &:hover,
    &:focus {
        color: $brightness-46;
        outline: 0;
    }
}

.subnav-link--current-section {
    &,
    &:hover,
    &:focus {
        font-weight: 700;
    }
}
