.menu-search {
    box-sizing: border-box;
    display: block;
    margin-left: 13px;
    max-width: gs-span(5);
    position: relative;
    width: 100%;
    margin-bottom: $gs-baseline * 2;
    margin-right: $veggie-burger / 2 + $gs-gutter;

    @include mq(tablet) {
        margin-left: 22px;
    }
}

.menu-search__search-box {
    @include fs-textSans(5);
    background-color: rgba($brightness-100, .1);
    border: 0;
    border-radius: 1000px;
    box-sizing: border-box;
    color: $brightness-100;
    font-size: 20px;
    height: $gs-row-height;
    padding-left: 38px;
    vertical-align: middle;
    width: 100%;

    &::placeholder {
        color: $brightness-100;
    }

    &:focus {
        outline: none;
        padding-right: $gs-gutter * 2;

        &::placeholder {
            opacity: 0;
        }
    }
}

.menu-search__glass {
    position: absolute;
    left: 10px;
    top: 7px;

    .inline-search-36__svg {
        fill: $brightness-100;
        height: 22px;
        width: 22px;
    }
}

.menu-search__submit {
    background: transparent;
    border: 0;
    bottom: 0;
    cursor: pointer;
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: $gs-gutter * 2.5;

    &:before,
    &:after {
        border: 2px solid $brightness-100;
        border-left: 0;
        border-top: 0;
        content: '';
        display: block;
        position: absolute;
        right: 14px;
    }

    &:before {
        height: $gs-baseline;
        top: $gs-baseline - 1;
        transform: rotate(-45deg);
        width: $gs-baseline;

        @include mq(desktop) {
            top: $gs-baseline + 2;
        }
    }

    &:after {
        border-right: 0;
        top: 17px;
        width: 20px;
    }

    // Reveals search button & retains visibility of search button when form loses focus
    .menu-search__search-box:focus ~ &,
    &:focus,
    &:active {
        opacity: 1;
        outline: none;
        pointer-events: all;
    }
}
