$cp-scott-width-mobile: 70px;
$cp-scott-width-tablet: 80px;

.fc-cp-scott__quote {
    display: block;
    color: $brightness-46;
}

.fc-cp-scott__quote-line {
    @include mq(mobileLandscape) {
        display: block;
    }
}

.fc-cp-scott__citation {
    display: block;
    color: $brightness-7;
}

.fc-cp-scott__portrait {
    width: $cp-scott-width-mobile;

    @include mq(tablet) {
        width: $cp-scott-width-tablet;
    }
}

.fc-cp-scott__avatar {
    float: right;
    width: $cp-scott-width-mobile - 10;
    height: $cp-scott-width-mobile - 10;
    border-radius: 100%;
    overflow: hidden;
    background-color: darken($opinion-faded, 5);

    @include mq($until: mobileLandscape) {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    @include mq(tablet) {
        margin-top: -$gs-baseline / 2;
        margin-bottom: $gs-baseline / 2;
        width: $cp-scott-width-tablet - 10;
        height: $cp-scott-width-tablet - 10;
        margin-left: -1px;
    }

    @include mq(leftCol) {
        margin-top: 0;
        margin-bottom: 0;
        float: none;
    }

    @include mq(wide) {
        .has-page-skin & {
            float: right;
            margin-top: -$gs-baseline / 2;
            margin-bottom: $gs-baseline / 2;
        }
    }
}

.fc-cp-scott__text {
    @include fs-headline(1);
    clear: left;
    margin-right: $cp-scott-width-mobile + $gs-gutter / 2;
    line-height: $gs-baseline * 4 / 3;

    .inline-quote svg {
        width: $gs-gutter/2 + $gs-baseline/2;
        height: auto;
        fill: $brightness-46;
    }

    @include mq(mobileLandscape) {
        clear: none;
        float: right;
        margin-right: $gs-gutter / 2;
        margin-top: 2px;
    }

    @include mq(tablet) {
        margin-top: 0;
    }

    @include mq(leftCol) {
        float: none;
        margin-right: 0;
    }

    @include mq(wide) {
        .has-page-skin & {
            float: right;
            margin-right: $gs-gutter / 2;
        }
    }
}
