.veggie-burger {
    background-color: $highlight-main;
    cursor: pointer;
    display: block;
    height: $veggie-burger;
    width: $veggie-burger;
    position: relative;
    border: 0;
    border-radius: 50%;
    outline: none;
    user-select: none;
    z-index: $zindex-main-menu - 1;

    // menu is open
    .new-header--open &,
    .header-top-nav--open & {
        z-index: $zindex-main-menu + 2;
    }

    // Don't show menu on opera mini: https://wp-mix.com/css-target-opera/
    x:-o-prefocus & {
        display: none;
    }
}

.veggie-burger__icon {
    top: 50%;
    display: block;
    margin-top: -($gs-baseline / 6) / 2;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &,
    &:before,
    &:after {
        background-color: currentColor;
        content: '';
        height: $gs-baseline / 6;
        left: 0;
        position: absolute;
        width: 20px;
    }

    &:before {
        top: -$gs-baseline / 2;
    }

    &:after {
        bottom: -$gs-baseline / 2;
    }

    // transform burger into cross
    .new-header--open &,
    .header-top-nav--open & {
        background-color: transparent;

        &:before {
            top: 0;
            transform: rotate(-45deg);
        }

        &:after {
            bottom: 0;
            transform: rotate(45deg);
        }
    }
}
