// Specific values for veggie-burger and menu
$menu-spacing-left-small: $gs-gutter * 2 + $gs-gutter / 2;
$menu-spacing-left-medium: $gs-gutter * 2 + $gs-gutter;

$search-and-edition-height-desktop: 42px;

$veggie-burger: 42px;

$pillar-height: 42px;
$pillar-width--desktop: 134px;
$pillar-width--leftCol: 160px;
$pillar-padding: $gs-gutter / 2;

// TODO: replace with pillar height variable
$slim-nav-height: 44px;
