.inline-icon__fallback {
    display: none !important;
}

.no-svg {
    .inline-icon {
        display: none !important;
    }

    .inline-icon__fallback {
        display: block !important;
    }
}
