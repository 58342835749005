.badge-slot {
    @include mq($until: leftCol) {
        float: left;
        margin-right: $gs-gutter / 2;
    }

    @include mq(desktop) {
        article:not(.content--type-immersive) & {
            margin-bottom: $gs-baseline / 2;
        }
    }
}

.badge-slot__img {
    display: block;
    height: 42px;
    width: auto;

    @include mq(leftCol) {
        height: 54px;
    }
}

// GT: Temporary fix to turn the black badge to white, we need a more perm fix (probably using Format...)
.content--media a[href*='series/guardian-200'] .badge-slot__img {
    filter: brightness(0) invert(1);
}
