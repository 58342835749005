$video-width-desktop: 700px;

.fc-container--video {
    padding-bottom: 0;
    margin-bottom: $gs-baseline;

    .gs-container {
        background-color: $brightness-7;
    }

    .fc-container__inner {
        border-top: 0;
    }

    .fc-container__toggle {
        color: $brightness-86;

        &:hover, &:focus {
            color: $brightness-100;
        }
    }

    .fc-item__image-container {
        display: block;
    }

    .u-responsive-ratio {
        padding-bottom: 56.3%;
    }

    .u-responsive-ratio.vertical-video {
        padding-bottom: 177%;
    }

    .gu-media-wrapper .vjs-paused.vjs-has-started .vjs-control-bar {
        bottom: -$gs-baseline * 5;
    }

    .vjs-big-play-button {
        @include mq(desktop) {
            display: none;
        }
    }


    .vertical-video-width {
        width: 300px;
    }
    .vertical-video-height {
        height: 533px;
    }
    .media__container--hidden {
        display: block !important; // To override existing important
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 2;

        video {
            display: none;
        }
    }

    .gu-media-wrapper {
        background-color: transparent;
    }

    .fc-item__video-fallback,
    .gu-media {
        @include mq(desktop) {
            opacity: .3;
            transition: opacity .4s ease-out;
        }
    }

    .treats__treat {
        background-color: transparent;
        border-color: rgba(255, 255, 255, .3);

        &:hover {
            border-color: #ffffff;
            background-color: #ffffff;
            color: $brightness-7;
        }
    }
    @include mq($until: desktop) {
        .u-responsive-ratio video {
            top: inherit;
            bottom: 0;
            height: auto;
        }

        .fc-item__video-container .vjs-big-play-button {
            top: (gs-height(3) - (get-line-height(textSans, 3) + 4px)) / 2;
        }

        .fc-item__video-fallback {
            margin-top: gs-height(3) - (get-line-height(textSans, 3) + 4px);
        }
    }
}

.fc-container--video-no-fill-sides {
    background-color: transparent;
}

.video-title {
    color: #ffffff;
    display: block;
    margin-bottom: $gs-baseline;

    &:after {
        content: none;
    }

    a:hover {
        color: #ffffff;
        border-bottom: #ffffff;
    }
}

.video-playlist {
    position: relative;
    overflow: hidden;
    @include mq(desktop, leftCol) {
        background-color: darken($brightness-7,  5%);

        .has-page-skin & {
            background-color: transparent;
        }
    }
}

.video-playlist--end .video-playlist__control--next,
.video-playlist--start .video-playlist__control--prev {
    opacity: 0;
    pointer-events: none;
}

.video-playlist--end .vertical-video-playlist__icon--next,
.video-playlist--start .vertical-video-playlist__icon--prev {
    pointer-events: none;
    cursor: default;
    border: 1px solid #ffffff;
    background-color: #101010;

    svg {
        fill: #ffffff;
    }
}

.video-playlist__inner {
    margin: 0;
    font-size: 0; // to remove spacing between inline-blocked children
    white-space: nowrap;
    padding-right: $gs-gutter / 2;
    @include mq(mobileLandscape) {
        padding-right: $gs-gutter;
    }

    @include mq($until: desktop) {
        &:not(.vertical-video-height) {
            overflow-x: scroll;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            transform: none !important;
        }
    }

    &:is(.vertical-video-height) {
        transition: transform .4s ease-out;
    }

    @include mq(desktop) {
        padding-right: 0;
        transition: transform .4s ease-out;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .video-playlist__item {
        background-color: $brightness-7;
    }
}

.vertical-video-playlist__control {
    width: 300px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    padding-bottom: 24px;
    @include mq($from: 375px, $until: phablet) {
        position: absolute;
        bottom: 80px;
        right: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        z-index: 10;
        width: calc(100% - 320px);
        padding-bottom: 0;
    }

    @include mq($from: mobileLandscape, $until: phablet) {
        width: calc(100% - 330px);
    }

    @include mq(leftCol)  {
        margin-left: 180px;
    }

    @include mq(wide)  {
        margin-left: 260px;
    }

}

.video-playlist__control {
    display: none;
    @include mq(desktop) {
        width: gs-span(2);
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;

        &:hover .video-playlist__icon,
        &:focus .video-playlist__icon {
            background-color: $highlight-main;

            svg {
                fill: $brightness-7;
            }
        }
    }
}

.vertical-video__counter {
    color: $brightness-100;
    user-select: none;
}

.video-playlist__overlay {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    height: 533px;
    @include mq(desktop) {
        &:hover .video-playlist__icon,
        &:focus .video-playlist__icon {
            background-color: $highlight-main;

            svg {
                fill: $brightness-7;
            }
        }
    }
}

.video-playlist__control--prev {
    left: 0;
    @include mq(leftCol) {
        width: gs-span(2) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }
    @include mq(wide) {
        width: gs-span(3) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }

    .video-playlist__icon {
        right: $gs-gutter;

        svg {
            margin-left: -2px;
        }
    }
}

.video-playlist__control--next {
    right: 0;
    @include mq(leftCol) {
        width: gs-span(3) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }
    @include mq(wide) {
        width: gs-span(4) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }

    .video-playlist__icon {
        left: $gs-gutter;

        svg {
            margin-right: -2px;
        }
    }
}

.video-playlist__control--prev.vertical-video {
    width: 10px;

    @include mq($from: mobileLandscape) {
        width: 20px;
    }
    @include mq(leftCol) {
        width: gs-span(2) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }
    @include mq(wide) {
        width: gs-span(3) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2);
        }
    }
}

.video-playlist__control--next.vertical-video {
    width: calc(100% - 310px);

    @include mq($from: mobileLandscape) {
        width: calc(100% - 320px);
    }
    @include mq($from: mobileMedium, $until: phablet) {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 25%);
}

    @include mq(leftCol) {
        width: gs-span(8) + $gs-gutter * 2;
    }
    @include mq(wide) {
        width: gs-span(9) + $gs-gutter * 2;
    }
}

.video-playlist__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    text-align: center;

    svg {
        fill: #ffffff;
        width: 9px;
        height: 30px;
    }
}

.vertical-video-playlist__icon {
    display: inherit;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    border: 1px solid $brightness-100;
    background-color: $brightness-100;
    text-align: center;

    svg {
        fill: $brightness-7;
        width: 9px;
        height: 30px;
    }

    &:hover,
    &:focus {
        background-color: $brightness-60;
        border-color: $brightness-60;

        svg {
            fill: $brightness-7;
        }
    }
}

.youtube-media-atom__play-button {
    background-color: $brightness-7;
    border-radius: 50%;
    color: transparent;
}

.video-playlist__item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 70%;
    background-color: $brightness-7;
    margin-left: $gs-gutter / 2;
    margin-bottom: $gs-baseline;

    @include mq(mobileLandscape) {
        margin-left: $gs-gutter;
    }
    @include mq(desktop) {
        width: $video-width-desktop;
        margin: 0;
    }

    @include mq($until: desktop) {
        .video-container-overlay-link {
            z-index: 3;
        }
    }
}
.video-playlist__item.vertical-video-width,
.video-playlist__item.vertical-video {
    margin-left: 0;
}

.video-playlist__item--first {
    @include mq(desktop, leftCol) {
        margin-left: gs-span(2);

        .has-page-skin & {
            margin-left: 0;
        }
    }
    @include mq(leftCol) {
        margin-left: 0;

        .has-page-skin & {
            margin-left: 0;
        }
    }
}

.video-playlist__item--active {
    .vjs-big-play-button {
        display: block;
    }

    .fc-item__video-fallback,
    .gu-media,
    .video-overlay {
        opacity: 1;
    }
}

.fc-item--pillar-news.video-playlist__item.video-playlist__item--paid-for .fc-item__kicker,
.fc-item--pillar-news.video-playlist__item.video-playlist__item--paid-for .video-overlay__duration {
    color: $labs-main;
}

.fc-item--pillar-news.video-playlist__item.video-playlist__item--paid-for .vjs-big-play-button .vjs-control-text {
    background-color: $labs-main;
}

.fc-item--pillar-news.video-playlist__item.video-playlist__item--paid-for .video-overlay {
    border-top-color: $labs-main;
}

.vjs-big-play-button .vjs-control-text,
.youtube-media-atom__play-button.vjs-control-text {
    // Sets a base play button/icon size for all video players
    @include video-icon-size($garnett-x-large-button-size, $garnett-x-large-button-icon);

    // Sets specific play button/icon size for fronts depending on layout
    .fc-item--full-media-75-tablet &,
    .fc-item--full-media-50-tablet &,
    .fc-item--three-quarters-tablet &,
    .fc-item--half-tablet & {
        @include video-icon-size($garnett-large-button-size, $garnett-large-button-icon);
        @include mq($from: mobileLandscape) {
            @include video-icon-size($garnett-x-large-button-size, $garnett-x-large-button-icon);
        }
    }

    .fc-item--third-tablet & {
        @include video-icon-size($garnett-large-button-size, $garnett-large-button-icon);
        @include mq($from: mobileLandscape, $until: tablet) {
            @include video-icon-size($garnett-x-large-button-size, $garnett-x-large-button-icon);
        }
    }

    .fc-item--standard-tablet & {
        @include mq($from: tablet) {
            @include video-icon-size($garnett-medium-button-size, $garnett-medium-button-icon);
        }
    }

    .fc-item--list-media-mobile & {
        @include mq($until: tablet) {
            @include video-icon-size($garnett-small-button-size, $garnett-small-button-icon);
        }
    }
}

.video-title--leftcol {
    display: none;
    @include mq(leftCol) {
        position: relative;
        height: $video-width-desktop / 16 * 9;
        display: inline-block;
        width: gs-span(2) + $gs-gutter * 2;
        padding: ($gs-baseline / 2) $gs-gutter;
        box-sizing: border-box;
        margin-bottom: 0;
        white-space: normal;
    }
    @include mq(wide) {
        width: gs-span(3) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2) + $gs-gutter * 2;
        }
    }

    .inline-guardian-video-logo svg {
        margin-top: 4px;
        max-width: 100%;

        &:hover .inline-guardian-video-logo__title {
            fill: #ffffff;
        }
    }

    .has-page-skin & {
        display: none;
    }
}
.vertical-video-title--leftcol {
    position: relative;
    height: 100%;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 0;
    white-space: normal;
    width: 10px;

    @include mq($from: mobileLandscape) {
        width: 20px;
    }

    @include mq(leftCol) {
        position: relative;
        height: $video-width-desktop / 16 * 9;
        display: inline-block;
        width: gs-span(2) + $gs-gutter * 2;
        padding: ($gs-baseline / 2) $gs-gutter;
        box-sizing: border-box;
        margin-bottom: 0;
        white-space: normal;
    }
    @include mq(wide) {
        width: gs-span(3) + $gs-gutter * 2;

        .has-page-skin & {
            width: gs-span(2) + $gs-gutter * 2;
        }
    }

    .inline-guardian-video-logo svg {
        margin-top: 4px;
        max-width: 100%;

        &:hover .inline-guardian-video-logo__title {
            fill: #ffffff;
        }
    }

    .has-page-skin & {
        display: none;
    }
}

.video-playlist__item--first {
    @include mq(desktop) {
        margin-left: gs-span(2);
    }
    @include mq(leftCol) {
        margin-left: 0;

        .has-page-skin & {
            margin-left: gs-span(2);
        }
    }
}

.video-overlay {
    position: absolute;
    z-index: 2;
    white-space: normal;
    padding: 0 $gs-gutter/4 $gs-baseline * 2;
    box-sizing: border-box;
    border-top: 1px solid $highlight-main;
    color: #ffffff;
    background-color: rgba(0, 0, 0, .9);
    min-height: gs-height(3);
    margin-bottom: -(get-line-height(textSans, 3) + 4px);
    pointer-events: auto;
    width: 100%;
    @include mq(desktop, leftCol) {
        opacity: 0;
        transition: opacity .4s ease-out;
    }
    @include mq(desktop) {
        position: absolute;
        top: $gs-baseline;
        left: $gs-gutter;
        width: gs-span(3);
    }

    .video-playlist__item .vjs-playing ~ & {
        @include mq(desktop) {
            visibility: hidden;
            opacity: 0;
        }
    }

    .video-overlay__headline {
        @include fs-headline(2);
        font-weight: 400;
        @include mq(mobileLandscape) {
            @include fs-headline(3);
            font-weight: 400;
        }
        @include mq(desktop) {
            min-height: get-line-height(headline, 3) * 4;
            overflow: hidden;
        }

        .fc-item__headline {
            color: #ffffff;
        }
    }

    .inline-icon svg {
        position: relative;
        height: .7em;
        width: auto;
    }

    .fc-item__title--quoted .inline-quote {
        fill: #ffffff;
    }

    .fc-item__link {
        color: #ffffff;

        &:visited {
            color: $brightness-86;
        }
    }

    .fc-item__byline,
    .fc-item__kicker {
        color: $highlight-main;
    }

    .fc-item__kicker {
        padding: 0;
        margin: 0;
    }
}

.vertical-playlist__margin {
    margin-bottom: 20px;
    @include mq($from: phablet) {
        margin-bottom: 24px
    }

}

.vertical-video-overlay {
    position: absolute;
    z-index: 2;
    white-space: normal;
    padding: 8px;
    box-sizing: border-box;
    color: #ffffff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 25.04%);
    pointer-events: auto;
    width: 100%;
    bottom: 0;

    .video-playlist__item .vjs-playing ~ & {
        visibility: hidden;
        opacity: 0;
    }

    .video-overlay__headline {
        @include fs-headline(2);
        font-weight: 400;
        @include mq(mobileLandscape) {
            @include fs-headline(3);
            font-weight: 400;
        }
        @include mq(desktop) {
            overflow: hidden;
        }

        .fc-item__headline {
            color: #ffffff;
        }
    }

    .inline-icon svg {
        position: relative;
        height: .7em;
        width: auto;
    }

    .fc-item__title--quoted .inline-quote {
        fill: #ffffff;
    }

    .fc-item__link {
        color: #ffffff;

        &:visited {
            color: $brightness-86;
        }
    }

    .fc-item__byline,
    .fc-item__kicker {
        color: $highlight-main;
    }

    .fc-item__kicker {
        padding: 0;
        margin: 0;
    }
}

.video-overlay__duration {
    @include fs-textSans(3);
    position: absolute;
    bottom: 2px;
    color: $brightness-86;
}

.vertical-video-overlay__duration {
    @include fs-textSans(2);
    @include mq($until: mobileMedium) {
        margin-right: 8px;
    }
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, .7);
    width: fit-content;
    padding: 4px 12px;
    border-radius: 16px;
    color:  $brightness-100;
    font-weight: 700;
    z-index: 2;
}

.fc-item__video-container {
    .youtube-media-atom {
        z-index: 1;

        &.no-player,
        .is-not-modern & {
            z-index: 0;
        }
    }
}

.vertical-video__ext-link {
    @include fs-textSans(2);
    color: $brightness-100;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 16px;

    @include mq($until: phablet) {
        width: 80%
    }

    @include mq(leftCol)  {
        margin-left: 180px;
    }

    @include mq(wide)  {
        margin-left: 260px;
    }

    a {
        text-decoration: underline;
        color: $brightness-100;
    }

}

