.menu-item {
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;

    .menu-group--primary > & {
        @include mq(desktop) {
            float: left;
            overflow: visible;
            width: $pillar-width--desktop;
            padding: 0 $pillar-padding $gs-baseline;

            &:first-child {
                padding-left: 0;
                width: $pillar-width--desktop -$gs-gutter / 2 - 1px;
            }
        }

        body:not(.has-page-skin) & {
            @include mq(leftCol) {
                &:first-child {
                    width: $pillar-width--leftCol -$gs-gutter / 2 - 1px;
                }

                width: $pillar-width--leftCol;
            }
        }
    }
}

.menu-item-divider {
    height: 1px;
    width: 100%;
    background: $brand-pastel;
    border: 0;
    margin: $gs-baseline/2 0 0 $menu-spacing-left-small;

    @include mq(tablet) {
        margin-left: $menu-spacing-left-medium;
    }

    @include mq(desktop) {
        margin-left: ($gs-baseline / 2) 0;
    }
}

.menu-item__title {
    @include fs-textSans(5);
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    color: $brightness-100;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    outline: none;
    padding: 8px $veggie-burger / 2 + $gs-gutter / 2 8px $menu-spacing-left-small;
    position: relative;
    text-align: left;
    width: 100%;

    .menu-group--membership & {
        color: $highlight-main;
        font-weight: 700;
    }

    @include mq(tablet) {
        padding-left: $menu-spacing-left-medium;
    }

    @include mq(desktop) {
        font-size: 16px;
        line-height: 1.2;
        padding: ($gs-baseline / 2) 0;
    }

    &:hover,
    &:focus {
        color: $highlight-main;
        text-decoration: none;
    }

    &:focus {
        text-decoration: underline;
    }

    > * {
        pointer-events: none;
    }

    .menu-group--primary > *:not(:last-child) > &,
    &[data-link-name='nav2 : The Guardian app'],
    &[data-link-name='nav2 : facebook'],
    .menu-group--footer > *:first-child &,
    .menu-group--membership > *:first-child & {
        @include mq($until: desktop) {
            &:not([aria-expanded='true']):after {
                background-color: $brand-pastel;
                bottom: 0;
                content: '';
                display: block;
                height: 1px;
                left: $menu-spacing-left-small;
                right: 0;
                position: absolute;

                @include mq(tablet) {
                    left: $menu-spacing-left-medium;
                }
            }
        }
    }

    &[data-link-name='nav2 : The Guardian app'],
    &[data-link-name='nav2 : facebook'],
    .menu-group--footer > *:first-child & {
        @include mq($until: desktop) {
            margin-top: $gs-baseline * 2;

            &:after {
                bottom: auto;
                top: 0;
            }
        }
    }

    .menu-group--membership > *:first-child & {
        @include mq($until: desktop) {
            &:not([aria-expanded='true']):after {
                bottom: auto;
                top: 0;
            }
        }
    }

    // only match the ones, which are not in --secondary
    .menu-group--primary > .menu-item > & {
        @include fs-headline(4);
        font-weight: 700;
        color: $brightness-100;
        padding-bottom: 18px;
        padding-top: $gs-baseline / 2;
    }
}

.menu-item--brand-extension {
    margin-right: 0;
    margin-top: -$gs-baseline / 2;
    padding-bottom: 0;
}

.menu-item__title--brand-extension {
    @include fs-headline(3);
    font-weight: 700;

    body:not(.has-page-skin) & {
        @include mq(wide) {
            @include fs-headline(4, true);
            padding-top: 5px;
        }
    }
}

.menu-item__icon,
.menu-item__toggle {
    left: 25px;
    position: absolute;

    @include mq(tablet) {
        left: 35px;
    }

    @include mq(desktop) {
        display: none;
    }
}

.menu-item__toggle {
    margin-top: -4px;

    [aria-expanded='true'] > & {
        margin-top: 2px;
    }

    &:before {
        border: 2px solid currentColor;
        border-top: 0;
        border-left: 0;
        content: '';
        display: inline-block;
        height: 8px;
        transform: rotate(45deg);
        width: 8px;

        [aria-expanded='true'] > & {
            transform: rotate(-135deg);
        }
    }
}

.menu-item__icon {
    margin-left: -3px;

    .inline-icon__svg {
        fill: currentColor;
    }

    .inline-home__svg {
        height: 16px;
        width: 16px;
    }

    .inline-log-off__svg {
        height: .8em;
    }

    .inline-share-facebook__svg,
    .inline-share-twitter__svg {
        margin-left: -6px;
        margin-top: -5px;
    }
}
