.pillar-link {
    box-sizing: border-box;
    font-family: $f-serif-headline;
    font-weight: 900;
    color: $brightness-100;
    cursor: pointer;
    display: block;
    // Weird number to get the most out of available space
    font-size: 15.4px;
    height: $pillar-height - $gs-baseline / 2;
    line-height: 1;
    padding: 9px 5px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include mq(mobileMedium) {
        font-size: 15.7px;
        padding: 9px 4px 0;
    }

    @include mq($from: 370px) {
        font-size: 16px;
        padding: 9px 5px 0;
    }

    @include mq(mobileLandscape) {
        font-size: 18px;
        padding: 7px 4px 0;
    }

    @include mq(tablet) {
        font-size: 22px;
        padding-top: $gs-baseline - 1px;
        height: $pillar-height + ($gs-baseline / 2);
        padding-right: $gs-gutter;
        padding-left: $pillar-padding;

        .new-header--slim &,
        .header-top-nav--slim & {
            height: $pillar-height + ($gs-baseline / 2);
            padding-top: $gs-baseline - 1px;
        }
    }

    @include mq(desktop) {
        padding-top: $gs-baseline - 5px;
        height: $pillar-height;
    }

    body:not(.has-page-skin) & {
        @include mq(wide) {
            font-size: 24px;
        }
    }

    &:before,
    &:not(.pillar-link--dropdown):after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
    }

    &:before {
        // Dividing lines
        border-left: 1px solid $brand-pastel;
        top: 0;
        z-index: 1;

        @include mq(tablet) {
            bottom: 17px;
        }

        @include mq(desktop) {
            bottom: .6em;

            .new-header--open &,
            .header-top-nav--open & {
                bottom: 0;
            }
        }
    }

    &:after {
        // Overlines
        border-top: 4px solid currentColor;
        left: 1px;
        right: 0;
        top: -4px;
        transition: transform .3s ease-in-out;
    }

    &:hover,
    &:focus {
        color: $brightness-100;
        text-decoration: none;

        &:after {
            transform: translateY(4px)
        }
    }

    .new-header--open &,
    .header-top-nav--open & {
        @include mq(desktop) {
            &:hover,
            &:focus {
                color: $highlight-main;
            }

            &:after {
                transform: translateY(4px)
            }
        }
    }
}

.pillar-link--current-section {
    &:after {
        transform: translateY(4px)
    }
}

.pillar-link--dropdown__icon {
    border: 1px solid currentColor;
    border-left: transparent;
    border-top: transparent;
    display: inline-block;
    height: 6px;
    margin-left: 2px;
    transform: translateY(-3px) rotate(45deg);
    transition: transform 250ms ease-out;
    vertical-align: middle;
    width: 6px;

    .pillar-link--dropdown:hover & {
        transform: translateY(0) rotate(45deg);
    }

    .new-header--open .pillar-link--dropdown &,
    .header-top-nav--open .pillar-link--dropdown & {
        transform: translateY(1px) rotate(-135deg);
    }

    .new-header--open .pillar-link--dropdown:hover & ,
    .header-top-nav--open .pillar-link--dropdown:hover & {
        transform: translateY(-2px) rotate(-135deg);
    }
}

.pillar-link--sections {
    color: $brightness-100;
    font-weight: 300;
    float: left;
    padding-right: $gs-gutter / 4;
    transition: color 250ms ease-out;

    &:hover,
    &:focus {
        color: $highlight-main;
    }
}
