.pillars {
    clear: right;
    margin: 0;
    padding: 0 0 0 ($gs-gutter / 2);

    .new-header:not(.new-header--slim) &,
    .header-top-nav:not(.header-top-nav--slim) & {
        &:after {
            content: '';
            border: 1px solid $brand-pastel;
            border-bottom: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: $pillar-height - $gs-baseline / 2;

            @include mq($until: tablet) {
                border-left: 0;
                border-right: 0;
            }

            @include mq(tablet) {
                height: $pillar-height + ($gs-baseline / 2);
            }

            @include mq(desktop) {
                height: $pillar-height;
            }
        }
    }

    @include mq(mobileLandscape) {
        padding-left: $gs-gutter;
    }

    @include mq(desktop) {
        .new-header--open &,
        .header-top-nav--open {
            z-index: $zindex-main-menu;
        }
    }

    .new-header--slim &,
    .header-top-nav--slim & {
        @include mq($until: tablet) {
            display: none;
        }
    }
}

.pillars__item {
    display: block;
    float: left;

    @include mq($until: mobileLandscape) {
        flex-grow: 1;
        text-align: center;
    }

    @include mq(desktop) {
        width: $pillar-width--desktop;
    }

    body:not(.has-page-skin) & {
        @include mq(leftCol) {
            width: $pillar-width--leftCol;
        }
    }

    &:first-child {
        margin-left: -$gs-gutter;

        @include mq(desktop) {
            width: $pillar-width--desktop + ($gs-gutter / 2);
        }

        body:not(.has-page-skin) & {
            @include mq(leftCol) {
                width: $pillar-width--leftCol + ($gs-gutter / 2);
            }
        }

        .pillar-link {
            padding-left: $gs-gutter;

            &:before {
                content: none;
            }
        }
    }
}
