.subnav {
    background-color: #ffffff;
    height: $veggie-burger - $gs-baseline / 2;
    overflow: hidden;
    position: relative;

    @include mq(tablet) {
        height: $pillar-height;
    }

    .gs-container {
        @include clearfix();
        box-sizing: border-box;

        @include mq(tablet) {
            border: 1px solid $brightness-86;
            border-top: 0;
            border-bottom: 0;
        }

        .footer__primary & {
            border-top: 1px solid $brightness-86;
        }
    }
}

.subnav--expanded {
    height: auto;

    .subnav-link--toggle-more {
        float: left;
    }
}

// TODO: reset list-style mixin
.subnav__list {
    box-sizing: border-box;
    line-height: 1;
    list-style: none;
    margin: 0;
    // Width of the container minus the rough width of the more toggle
    max-width: calc(100% - 60px);
    padding: 0 ($gs-gutter / 4);

    @include mq(mobileLandscape) {
        max-width: calc(100% - 70px);
        padding: 0 ($gs-gutter / 2 + $gs-gutter / 4);
    }

    .subnav--expanded & {
        padding-bottom: $gs-baseline;
        max-width: 100%;
        width: 100%;
    }
}

.subnav__item:not(.subnav__item--toggle-more) {
    display: block;
    float: left;
}

.subnav__item--parent:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid $brightness-7;
}

.new-header__multiline {
    background: $brightness-100;

    & .gs-container:after {
        @include multiline(4, $brightness-86, bottom);
        background-color: #ffffff;
        content: '';
        display: block;
        height: 13px;
        
        @include mq(tablet) {
            border: 1px solid $brightness-86;
            border-top: 0;
            border-bottom: 0;
        }
    }
}

@mixin subnavScheme ($colourOne, $colourTwo) {
    background-color: $colourOne;

    .gs-container {
        border-color: $colourTwo;
    }

    & ~ .new-header__multiline {
        background-color: $colourOne;

        .gs-container:after {
            @include multiline(4, $colourTwo, bottom);
            background-color: $colourOne;
            border-color: $colourTwo;
        }
    }
}

.subnav--comment {
    @include subnavScheme($opinion-faded, $brightness-86);
}

.subnav--media {
    @include subnavScheme($brightness-7, $brightness-20);

    .subnav-link  {
        color: $brightness-100;
    }
}
