a,
.u-fauxlink {
    color: $sport-dark;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
    &:active {
        color: $sport-bright;
        text-decoration: none;
    }
}
