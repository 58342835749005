/* ==========================================================================
   Global typography settings
   ========================================================================== */

/* Root base
   ========================================================================== */

html {
    font-family: $f-serif-text;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
body {
    line-height: 1.5;
    color: $brightness-7;
}

/**
 * Turn on kerning and ligatures
 * Causes issues in Chrome at the moment
 */

html,
body {
    text-rendering: optimizeSpeed;
}

.should-kern body {
    text-rendering: optimizeLegibility;
    font-feature-settings: 'kern';
    font-kerning: normal; // Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21
    font-variant-ligatures: common-ligatures;
}


/* Base headings
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}


/* Type helpers
   ========================================================================== */

/**
 * DEPRECATED:
 * Use font-scale mixins instead
 */


// SerifText
.type-5 { @extend %type-5 !optional; }


/* Default type elements
   ========================================================================== */

blockquote {
    margin: 0;
}
p {
    margin-top: 0;
    margin-bottom: ($gs-baseline/3)*2;
}
h3 {
    @include fs-bodyCopy(2);
    font-weight: normal;
    margin-bottom: 7px;
}
