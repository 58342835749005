/* ---------------- ARTICLE CONTAINER ------------------- */

#flagship-audio {
    .fc-container {

        .fc-date-headline {
            color: $brightness-100;
        }

        .fc-container__inner {
            border-color: $brightness-20;

            .fc-container__header {
                @include fs-headline(4, true);

                &::after {
                    display: none;
                    @include mq($from: leftCol) {
                        display: block;
                        content: '';
                        position: absolute;
                        height: 30px;
                        width: 1px;
                        background-color: $brightness-20;
                        right: -11px;
                        top: -6px;
                    }
                }

            }
        }

        .fc-item__container {
            flex-direction: row;

            &::before {
                background-color: #fc6dfb;
            }

            .fc-date-headline {
                color: $brightness-100;
            }

            .fc-item__media-wrapper {
                @include mq($until: phablet) {
                    margin: 0;
                }
                /*hack to maintain square images */
                @include mq($until: tablet) {
                    flex-basis: 15%;
                }
                @include mq($from: tablet, $until: desktop) {
                    flex-basis: 30%;
                }
                @include mq($from: desktop) {
                    flex-basis: 20%;
                }
                .u-responsive-ratio {
                    padding-bottom: 100%; /* this is horrible but it works */
                    height: 100%;
                    width: auto;

                    @include mq($from: desktop) {
                        width: 230px;
                    }
                }

                img {
                    object-fit: cover;
                }

            }
            .fc-item__content {
                max-width: 100%;
                justify-content: flex-start;
                min-height: 120px; /*hack to mantain square images at smaller breakpoints */
                @include mq($from: tablet , $until: wide) {
                    padding: 0 $gs-gutter $gs-gutter;
                }

                .fc-item__header {
                    flex: 0 1 auto;
                }

                .fc-item__standfirst {
                    flex: 0 1 auto;
                }

                .fc-item__title {
                    .fc-item__kicker {
                        color: #fc6dfb;
                    }
                }
                .fc-item__footer-meta-wrapper {
                    margin-top: auto;
                    .fc-item__media-meta {
                        .inline-icon {
                            background-color: #fc6dfb;
                        }
                    }
                }

            }
        }
    }

    /* ---------------- CONTRIBUTIONS BANNER ------------------- */

    .flagship-audio__contributions-banner,
    section.flagship-audio {
        position: relative;
        z-index: $zindex-ui;

        & > .fc-container__inner {
            @include mq(tablet) {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    background-color: rgba(0, 0, 0, .1);
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: 0;
                }
            }
        }
    }

    .flagship-audio__contributions-banner {
        background: rgb(8, 43, 97);
        background: linear-gradient(0deg, rgba(8, 43, 97, 1) 0%, rgba(36, 112, 163, 1) 100%);
        padding-bottom: 0;

        .flagship-audio__contributions-banner-holder {
            display: flex;
            flex-direction: row;

            h2 {
                @include fs-headline(4, true);
                color: #ffffff;
            }
            p {
                @include fs-bodyCopy(2);
                color: #ffffff;
            }
            a {
                max-width: 170px;
                border: 1px solid #ffffff;
                color: #ffffff;
            }
            .flagship-audio__contributions-banner-text {
                display: flex;
                flex-direction: column;
                flex: 0 0 300px;
                z-index: 10;
                .contributions__contribute {
                    background-color: transparent;
                    &:hover {
                        background-color: rgba(255, 255, 255, .15);
                    }
                }
            }
            .flagship-audio__contributions-banner-image {
                flex: 1 1 auto;
                display: flex;
                justify-content: center;
                z-index: 5;
                svg {
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    height: auto;
                    width: auto;
                    @include mq($until: phablet) {
                        height: 60%;
                        width: 60%;
                    }
                    path {
                        fill: #ff00f0;
                    }
                }
            }
        }

        /* override height and color of line separating fc-container__header & fc-container__body  */
        .fc-container__inner {
            .fc-container__header {
                &::after {
                    background-color: rgba(0, 0, 0, .1);
                    height: 300px;
                }

            }
        }
    }
}
