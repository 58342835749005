@mixin PillarSubnav ($colourOne) {
    .subnav__item--parent:after {
        border-left-color: $colourOne;
    }

    .subnav-link {
        &:hover,
        &:focus {
            color: $colourOne;
            text-decoration: none;
        }
    }
}

.subnav__list[data-pillar-title='News'] {
    @include PillarSubnav($news-main);
}

.subnav__list[data-pillar-title='Opinion'] {
    @include PillarSubnav($opinion-main);
}

.subnav__list[data-pillar-title='Sport'] {
    @include PillarSubnav($sport-main);
}

.subnav__list[data-pillar-title='Culture'] {
    @include PillarSubnav($culture-main);
}

.subnav__list[data-pillar-title='Lifestyle'] {
    @include PillarSubnav($lifestyle-main);
}


@mixin PillarLinks ($colourOne) {
    &:after {
        border-color: $colourOne;
    }
}

.pillar-link {
    &.pillar-link--News {
        @include PillarLinks($news-bright);
    }

    &.pillar-link--Opinion {
        @include PillarLinks($opinion-bright);
    }

    &.pillar-link--Sport {
        @include PillarLinks($sport-bright);
    }

    &.pillar-link--Culture {
        @include PillarLinks($culture-bright);
    }

    &.pillar-link--Lifestyle {
        @include PillarLinks($lifestyle-bright);
    }
}
