/* Static pages and misc
   ========================================================================== */

.page-header {
    @include fs-headline(3);
    padding: 0 0 ($gs-baseline/3)*4;
    margin: 0 $gs-gutter/2 $gs-baseline/3;
    border-bottom: 1px dotted $brightness-86;

    @include mq(mobileLandscape) {
        margin-left: $gs-gutter;
        margin-right: $gs-gutter;
    }
}

.page-sub-header {
    @include fs-header(2);
    padding: $gs-baseline/6 0 $gs-baseline/3;
    margin-bottom: $gs-baseline;

    .no-indent-article__zone &,
    .monocolumn-wrapper &,
    .fc-container__inner & {
        margin-left: 0;
        margin-right: 0;
    }
    > a {
        display: block;
    }
}

.message {
    padding: 11px;
    background-color: #ffffee;
}
