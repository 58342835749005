/* Columning helpers
   ========================================================================== */

.monocolumn-wrapper {
    padding-left: $gs-gutter / 2;
    padding-right: $gs-gutter / 2;

    @include mq(mobileLandscape) {
        padding-left: $gs-gutter;
        padding-right: $gs-gutter;
    }
    @include mq(tablet) {
        max-width: gs-span(8);
        margin-left: auto;
        margin-right: auto;
    }

    .page-header {
        margin-left: 0;
        margin-right: 0;
    }
}
@include mq(desktop) {
    .monocolumn-wrapper--no-limit-desktop {
        max-width: none;
    }
}


/* Layout helpers
   ========================================================================== */

/**
 * @todo Should these be moved to helpers?
 */

.component {
    margin-bottom: 20px;
}

.component__heading {}

.component--rhc {
    display: none;

    @include mq(desktop) {
        display: block;
        margin-top: $gs-baseline * 3;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: $gs-baseline * 3;
    }
}

/* Override default .gs-container breakpoints */
.gs-container {
    @include mq(tablet) {
        max-width: gs-span(9) + $gs-gutter * 2;
    }

    @include mq(desktop) {
        max-width: gs-span(12) + $gs-gutter * 2;
    }

    @include mq(leftCol) {
        max-width: gs-span(14) + $gs-gutter * 2;
    }

    @include mq(wide) {
        max-width: gs-span(16) + $gs-gutter * 2;
    }
}

/* Inline images: included here for faster painting
   ========================================================================== */

.img--inline {
    float: left;
    clear: left;
    width: $inArticleInlineImgWidth;

    @include mq(mobileLandscape) {
        width: gs-span(2);
    }

    figcaption {
        padding-top: $gs-baseline/3;
        word-wrap: break-word;
    }
}

/* Ajax loading helpers
   ========================================================================== */

.loading,
.preload-msg {
    padding: 50px 50px 250px;
    text-align: center;

    .loading__link,
    .accessible-link {
        @include fs-textSans(2);
        display: inline-block;
    }
}
.loading .loading__animation,
.preload-msg .is-updating {
    display: block;
    margin: 10px auto;
}
