.menu-group {
    box-sizing: border-box;
    display: flex;
    font-size: 18px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0 0 $gs-baseline;
    position: relative;

    @include mq(desktop) {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    [aria-expanded='false'] ~ & {
        @include mq($until: desktop) {
            display: none;
        }
    }
}

.menu-group--primary {
    padding-top: 0;

    @include mq(desktop) {
        flex-direction: row;
        flex-wrap: nowrap;
        order: 1;
    }
}

.menu-group--secondary {
    background-color: $brand-dark;
    margin-top: 0;
    padding-top: 0;

    @include mq(desktop) {
        background-color: transparent;
        padding-bottom: 0;
        width: 100%;
    }
}

.menu-group--membership {
    padding-bottom: 0;
    position: relative;
}

.menu-group--editions,
.menu-group--membership {
    padding-bottom: 0;

    .menu-group {
        background-color: $brand-dark;
    }
}

.menu-group--footer {
    @include mq(desktop) {
        bottom: 0;
        left: $pillar-width--desktop * 5 + $gs-gutter / 2 - 1px;
        padding-left: $pillar-padding;
        position: absolute;
        top: 0;
        width: $pillar-width--desktop;
    }

    body:not(.has-page-skin) & {
        @include mq(leftCol) {
            left: $pillar-width--leftCol * 5 + $gs-gutter / 2 - 1px;
            width: $pillar-width--leftCol;
        }
    }
}

.menu-group--footer,
.menu-group--primary > .menu-item:not(:first-child),
.menu-group--brand-extensions {
    &:before {
        @include mq(desktop) {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            border-left: 1px solid $brand-pastel;
        }
    }
}

.menu-group--brand-extensions {
    position: absolute;
    right: $gs-gutter + $gs-gutter / 2;
    top: -$pillar-height;
    bottom: 0;
    padding: $pillar-height $gs-gutter $gs-baseline $pillar-padding;
    width: 131px;

    body:not(.has-page-skin) & {
        @include mq(leftCol) {
            width: gs-span(2);
        }

        @include mq(wide) {
            width: gs-span(4);
            padding-top: 10px;
        }
    }
}
