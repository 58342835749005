.inline-icon {
    fill: #ffffff;

    svg {
        overflow: visible;
    }
}

.inline-icon--light-grey {
    fill: $brightness-86;
}

.inline-icon--black {
    fill: $brightness-7;
}

.inline-close--small svg {
    height: 100%;
    width: 45%;
}

.inline-tone-fill {
    fill: $sport-dark;
}


.inline-icon__fallback {
    display: none;
}
