/* ==========================================================================
    Pop-up
    ========================================================================== */
$popup-top: $headerHeight + $gs-baseline/2 + 3;

// Popups align with the label, so if there's a control icon, we need to account
// for it. The number is derived from the icon size on the control.
// TODO:
// use a proper icon size variabe when the icons are redrawn at mulptile sizes
$control-offset: 36 + $gs-gutter/2;

.popup--default {
    background: $brightness-100;
    border-radius: $gs-baseline / 4;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    left: 0;
    top: $popup-top - 2;
    padding: 0;
}

.popup {
    box-sizing: border-box;
    position: absolute;
    margin: 0;
    list-style: none;
    min-width: gs-span(2);

    @include mq(mobileLandscape) {
        right: auto;
        bottom: auto;
        top: $popup-top;
    }

    .brand-bar__item--has-control & {
        @include mq(tablet) {
            left: $control-offset;
        };

        .l-header--is-slim & {
            left: 0;
        }
    }

    .brand-bar__item--right & {
        @include mq(tablet) {
            left: auto;
            right: 0;
        }
    }
}

.popup__group {
    z-index: 3;
    margin: 0;
    padding: 0 $gs-gutter/2;
}

.popup__group-header {
    @include fs-textSans(2);
    color: $brightness-46;
    padding: $gs-baseline/2 $gs-gutter/2;
    border-top: 1px solid $brightness-86;
    padding-bottom: 0;
    margin: 0;

    &:first-of-type {
        border-top: 0;
    }
}

.has-popup {
    cursor: default;
    position: relative;
}

.popup-container {
    position: relative;
    z-index: 11;
}

.popup__toggle {
    cursor: pointer;

    // disclosure arrow
    &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        transform: translateY(-2px) rotate(45deg);
        border: 1px solid currentColor;
        border-left: transparent;
        border-top: transparent;
        margin-left: 2px;
        vertical-align: middle;
        // Prevents wobble on webkit browsers
        -webkit-backface-visibility: hidden;
        transition: transform 250ms ease-out;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &:after {
            transform: translateY(0) rotate(45deg);
        }
    }

    &.is-active,
    .is-active > &,
    &[aria-expanded=true],
    [aria-expanded=true] ~ &,
    .is-not-modern .brand-bar__item--has-control:hover &,
    .is-not-modern .is-signed-in.brand-bar__item--profile:hover & {
        &:after {
            transform: translateY(1px) rotate(-135deg);
        }

        &:hover {
            &:after {
                transform: translateY(-1px) rotate(-135deg);
            }
        }
    }
}

.l-header .popup__toggle {
    &:after {
        @include mq($until: tablet) {
            display: none;
        };
    }
}

// TODO: Combine parent selector (@at-root) or move context class
.l-header--is-slim.l-header .popup__toggle {
    &:after {
        display: none;
    }
}

.popup__item {
    display: block;
    line-height: 36px;

    a {
        border-bottom: 1px solid $brightness-86;
    }

    &:last-child {
        border-bottom: 0;
    };
}

.popup__item,
.popup__action {
    text-align: left;
}

.popup {
    .brand-bar__item--action,
    .brand-bar__item--action:hover {
        display: block;
        white-space: nowrap;
        color: $brightness-7;
        line-height: $gs-baseline * 3;
    }

    .brand-bar__item--inline-action {
        display: inline-block !important;
        margin-right: $gs-gutter * .25;
    }
}


/* Profile Menu
    ========================================================================== */
.brand-bar__item--profile {
    // stupid having to reset this, but :not was :not available to all in 2009
    @if ($old-ie == true) {
        .popup__toggle:hover:before,
        .popup__toggle:after {
            display: none !important;
        }
        &.is-signed-in .popup__toggle {
            display: inline-block;
        }
    } @else {
        &:not(.is-signed-in) .popup__toggle {
            &:before,
            &:after {
                display: none !important;
            }
        }
    }
}

/* Search Menu
    ========================================================================== */
.popup--search {
    background-color: $brightness-100;
    border-radius: $gs-baseline / 4;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    padding: $gs-baseline $gs-gutter 0;
    top: ($gs-baseline * 2) + $gs-baseline / 2;
    z-index: $zindex-main-menu + 1;
    outline: 0;
    left: auto;
    right: 250px;
}

body:not(.has-page-skin) {
    .popup--search {
        @include mq(wide) {
            right: 325px;
        }
    }
}
