// header styling

#flagship-audio {
    @include fs-headline(3);
    font-weight: 300;
    padding-top: 0;
    margin-bottom: 0;

    .ad-slot,
    .ad-slot--paid-for-badge {
        display: none !important;
    }

    .content--media {
        background-color: $brightness-7;
        .submeta__link {
            color: $brightness-100;
        }
    }

    /* ---------------- HEADER ------------------- */

    section.flagship-audio {
        background: rgb(8, 43, 97);
        background: linear-gradient(0deg, rgba(8, 43, 97, 1) 0%, rgba(36, 112, 163, 1) 100%);
        padding: 0;

        .flagship-audio__thrasher {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            .flagship-audio__content {
                padding-top: $gs-baseline/2;
            }

            /* ---------------- HEADER LOGO ------------------- */
            .flagship-audio__logo {
                position: absolute;
                top: $gs-baseline/2;


                @include mq($until: mobileLandscape) {
                    right: 0;
                }

                @include mq($from: mobileLandscape, $until: leftCol) {
                    right: $gs-gutter /2;
                }

                @include mq($from: leftCol, $until: wide) {
                    flex: 1 0 auto;
                }

                @include mq($until: phablet) {
                    svg {
                        transform-origin: top right;
                        transform: scale(.8);
                    }
                }
                @include mq($from: desktop) {
                    right: 10px;
                    width: 115px;
                }
                @include mq($from: wide) {
                    right: 90px;
                }
            }


            /* ---------------- HEADER CONTENT ------------------- */

            .flagship-audio__content-main {
                z-index: 9;
            }

            .flagship-audio__content {
                padding: 0;
                flex: 1 1 auto; /* what is width here */
                display: flex;
                flex-direction: row;

                margin-left: 0;


                &::before {
                    display: none;
                    // @include mq($from: leftCol) {
                    //     display: block;
                    //     content: '';
                    //     position: relative;
                    //     width: 1px;
                    //     background-color:white;
                    //     top: 0;
                    //     bottom: 0;
                    //     left: -10px;
                    // }
                }

                @include mq($until: desktop) {
                    flex-direction: column;
                    border: 0;
                    padding: 0;
                }

                .flagship-audio__description,
                .flagship-audio__title {
                    color: #ffffff;
                    max-width: 330px;
                }

                .flagship-audio__description {
                    @include fs-bodyCopy(2);
                    padding-top: 6px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    @include mq($until: mobileMedium) {
                        font-size: 15px;
                        line-height: 135%;
                    }
                }

                .flagship-audio__title {
                    @include fs-headline(8, true);
                    font-weight: 700;
                    color: #ffffff;
                    margin-bottom: 0;

                    @include mq($from: mobile, $until: phablet) {
                        @include fs-headline(6, true);
                    }
                    @include mq($until: mobileMedium) {
                        max-width: 60%;
                    }
                }

            }


            /* ---------------- HEADER ARTWORK ------------------- */
            .flagship-audio__artwork {

                @include mq($from: mobile, $until: phablet) {
                    flex: 1 1 auto;
                    justify-self: flex-end;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                }
                @include mq($from: phablet) {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: 8;
                }
                @include mq($from: desktop) {
                    right: 20px;
                }
                @include mq($from: wide) {
                    right: 100px;
                }
                .artwork-holder {
                    width: 100%;
                    top: 0;
                    @include mq($until: mobileMedium) {
                        margin-bottom: -$gs-baseline*4;
                    }
                    @include mq($from: mobileMedium, $until: mobileLandscape) {
                        margin-bottom: -$gs-baseline*3;
                    }
                    @include mq($from: mobileLandscape) {
                        margin-bottom: -$gs-baseline/2;
                    }
                    @include mq($from: phablet, $until: desktop) {
                        float: right;
                        position: relative;
                    }
                    svg {
                        overflow: visible;
                        @include mq($until: phablet) {
                            width: 100%;
                        }
                        path {
                            fill: #ff00f0;
                            &:nth-child(2) {
                                fill: #0a3168;
                            }
                        }
                    }

                }

                @include mq($from: mobile, $until: phablet) {
                    justify-content: center;
                }
            }

        }

    }


    /* ---------------- HEADER BUTTONS ------------------- */
    .flagship-audio__content-buttons {
        list-style: none;
        line-height: 1;
        padding: 40px 0 5px;
        background-color: transparent;
        color: $brightness-7;
        overflow: auto;
        display: block;
        margin: 0;

        @include mq($until: phablet) {
            display: none;
        }

        .flagship-audio__link-wrapper {
            display: flex;
            flex-direction: row;
        }
        .flagship-audio__subscribe-label {
            @include fs-textSans(1);
            margin-bottom: 6px;
            color: #ffffff;
            font-weight: 800;
            font-size: 17px;
            @include mq($from: phablet) {
                padding-top: 10px;
                margin-right: 8px;
            }
        }
    }

    .flagship-audio__button {
        @include fs-textSans(1);
        align-items: center;
        display: inline-flex;
        border: 1px solid #ffffff;
        color: #ffffff;
        padding: 10px 12px;
        border-radius: 20px;
        font-weight: 600;
        font-family: 'Guardian Text Sans Web';
        font-size: 14px;
        margin: 0 4px 8px 0;
        span {
            display: inline-flex;
        }

    }

    .flagship-audio__link:hover {
        text-decoration: none;

        .flagship-audio__button {
            background-color: rgba(255, 255, 255, .15);
        }
    }


    /* ---------------- HEADER BUTTTONS HACK FOR MOBILE ------------------- */
    #flagship-audio__mobile-only {
        display: none;

        @include mq($until: phablet) {
            display: block;
            padding: 0;

            ul.flagship-audio__content-buttons {
                display: block;
                background-color: #121212;
                margin: 0;
                padding: 4px 10px;
                @include mq($from: mobileLandscape) {
                    padding: 4px 20px;
                }

                .flagship-audio__subscribe-label {
                    color: $brightness-100;
                }
            }
        }
    }


}
