// Base rich link styles
//----------------------------------

.rich-link {
    background-color: $brightness-93;
    margin: 0;
    position: relative;
    overflow: hidden;

    a {
        color: inherit;
    }
}

.rich-link .u-faux-block-link--hover {
    background-color: $brightness-93;
}

.rich-link__title {
    font: inherit;
    line-height: inherit;
    padding: 0;
}

.rich-link .rich-link__header {
    @include fs-headline(3);
    font-weight: 500;
    padding: $gs-baseline / 3 $gs-gutter / 4 .5em;
    box-sizing: border-box;
    min-height: $gs-baseline * 3;
}

.rich-link__read-more {
    padding-left: $gs-gutter / 4;
}

.rich-link__arrow {
    display: inline-block;
}

.rich-link__read-more-text {
    @include fs-headline(1);
    display: inline-block;
    height: 30px;
    line-height: ($gs-baseline * 2) + 2;
    padding-left: 2px;
    vertical-align: top;
    font-weight: 500;
}

.rich-link__link .u-faux-block-link__overlay {
    z-index: 2;
}

.rich-link__container {
    position: relative;

    &:before {
        background-color: $sport-bright;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        z-index: 2;

    }

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

// In-article styles
//-----------------------

.element-rich-link {
    float: left;
    margin: 5px $gs-gutter $gs-baseline 0;
    clear: both;

    // Decouple rich link position from height of ads that are offset right.
    // This prevents the rich link from shifting when the height of the ad
    // changes. Currently the tall ads are allowed only on desktop and above.
    @include mq($from: desktop) {
        clear: left;
    }

    @include mq($until: mobileLandscape) {
        width: $gs-gutter * 6.5;
        margin-bottom: $gs-baseline / 2;
        margin-right: $gs-gutter / 2;

        .rich-link__header {
            @include fs-headline(1, true);
        }
    }

    @include mq(mobileLandscape) {
        width: gs-span(3);
    }

    @include mq(leftCol) {
        margin-left: -1 * (gs-span(2) + $gs-gutter);
        &.element--supporting {
            width: gs-span(4);
        }
    }

    @include mq(wide) {
        margin-left: -1 * (gs-span(3) + $gs-gutter);
        &.element--supporting {
            width: gs-span(5);
        }
    }
}
