$item-top-border-height: 1px;
$mobile-max-container-width: gs-span(8);
@include mq-add-breakpoint(containerWidestMobile, $mobile-max-container-width + $gs-gutter * 2);
$breakpoints: (
    tablet:  gs-span(9),
    desktop: gs-span(12),
    leftCol: gs-span(14),
    wide:    gs-span(16)
);

@mixin fc-container-head-common {
    .fc-container__inner,
    .facia-container__inner,
    .fc-container__pagination,
    .index-page-header {
        overflow: hidden;
        position: relative;
        margin-left: $gs-gutter / 2;
        margin-right: $gs-gutter / 2;

        @include mq(mobileLandscape) {
            margin-left: $gs-gutter;
            margin-right: $gs-gutter;
        }

        @include mq(tablet) {
            margin-left: auto;
            margin-right: auto;
            padding-left: $gs-gutter;
            padding-right: $gs-gutter;
            width: $mobile-max-container-width;
        }

        @each $breakpoint, $container-width in $breakpoints {
            @include mq($breakpoint) {
                width: $container-width;
            }
        }
    }
    .fc-container__inner--full-span,
    .facia-container__inner--full-span {
        @each $breakpoint, $container-width in $breakpoints {
            @include mq($breakpoint) {
                width: $container-width + ($gs-gutter * 2);
            }
        }
        @include mq(tablet) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@mixin vertical-item-separator {
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 100%;
        border-left: 1px solid $brightness-86;
    }
}

@mixin item--hide-tone-border {
    .item__tonal-border {
        border-top-width: 0 !important;
    }
    .item__image-container {
        margin-top: 0 !important;
    }
}

@mixin show-only-if-svg-is-supported {
    display: none;
    .svg & { display: block; }
}

@mixin hide-icon-in-title {
    .item--video .item__title--has-icon-mobile,
    .item--gallery .item__title--has-icon-mobile {
        padding-left: 0;

        .i { display: none; }
    }
}

@mixin showcaseAdPadding($span, $height) {
    padding-top: (aspect-ratio-height(5, 3, gs-span($span)) + gs-height($height));
    //The image spills into the right column, so we assume it's 5:3 and gs-span(13)/gs-span(12) wide and use that to set the padding-top.
}
