/**
 * Position pseudo elements on left and right of the content
 *
 * $container-width {Number} - width of the content
 *
 * @output pseudo element with left/right offset
 */

@mixin side-margins-position($container-width) {
    // stupid fix for sasslint AST error
    $size: $container-width + $gs-gutter * 2;

    &:before,
    &:after {
        width: calc((100% - #{$size}) / 2);
    }
}


/* Site borders on left and right of the content
   ========================================================================== */

.l-side-margins {
    position: relative;
    height: 100%;

    @include mq(tablet) {
        &:after,
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            height: 100%;
            width: 0;
            pointer-events: none;
        }
        &:before {
            left: 0;
            border-right: 1px solid rgba($brightness-46, .3);
        }
        &:after {
            right: 0;
            border-left: 1px solid rgba($brightness-46, .3);
        }
        @include side-margins-position(gs-span(9));
    }

    @include mq(desktop) {
        @include side-margins-position(gs-span(12));
    }

    @include mq(leftCol) {
        @include side-margins-position(gs-span(14));
    }

    @include mq(wide) {
        @include side-margins-position(gs-span(16));

        .has-page-skin & {
            margin-left: auto;
            margin-right: auto;
            width: gs-span(12) + ($gs-gutter*2);
        }
    }
}
.l-side-margins--media {
    @include mq(tablet) {
        &:after,
        &:before {
            background: rgba(0, 0, 0, .25);
            border-color: $brightness-20;

            .container__banding + .container__banding & {
                background: rgba($brightness-7,  .15);
            }
        }
    }
}
.l-side-margins--paidfor {
    @include mq(tablet) {
        &:after,
        &:before {
            border-color: $brightness-60;
        }
    }
}
