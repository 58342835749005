.edition-picker-toggle-button {
    border: 0;
    background: none;
    text-align: left;
}

button.edition-picker-toggle-button:hover {
    color: #ffffff;
    text-decoration: underline;
}
