@charset 'UTF-8';

.js-on .js-hidden,
.js-off .js-visible,
/*.js-on*/.is-modern .modern-hidden,
.is-not-modern .modern-visible {
    display: none;
}

.is-off {
    display: none;
}

.current {
    font-weight: bold;
}

.is-on {
    display: block;
}

.has-cursor {
    cursor: pointer;
}

.maxed {
    width: 100%;
}

.shut > .panel {
    overflow: hidden;
    position: relative;
    max-height: 0;
    padding-top: 0;
}

.update {
    float: right;
}

.is-updating {
    display: none;
    width: 40px;
    height: 20px;
    background-size: 100%;
    margin-top: 18px;

    &.is-active {
        display: inline-block;
    }
}

.is-scroll-blocked {
    overflow: hidden;
}

.is-updating-cursor {
    cursor: wait;
}

body.is-updating-js {
    .u-block-during-update {
        pointer-events: none;
    }
}

.is-updating--dark {
    width: 36px;
    height: 12px;
}

.is-live-icon {
    padding: 1px 4px 2px;
    margin: 1px 3px 0 0;
    font-size: 10px;
    font-style: normal;
    line-height: 1;
    color: #ffffff;
    background-color: #ec1c1c;
    display: inline-block;
}

.id--signed-out .sign-in-required {
    display: none;
}

.is-sticky {
    position: fixed;
    top: 0;
}
