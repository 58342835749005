dt {
    font-weight: bold;
}

// Addresses paddings set differently in IE6/7
menu,
ol,
ul {
    padding: 0;
    margin-left: 25px;
}

// Corrects list images handled incorrectly in IE7
nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

// Some list helpers

// Remove margin and bullets from lists.
.u-unstyled {
    @extend %u-unstyled;
}

// List of inline items
.inline-list {
    @extend %u-unstyled;
}

.inline-list__item {
    display: inline-block; // Prevent linebreak within an item
}
