// Google search overrides
.gsc-search-box {
    position: relative !important;
}

.gsc-search-button,
.gsib_a {
    margin: 0 !important;
    padding: 0 !important;
    width: 0 !important;
}

.gsc-search-button-v2 {
    padding: 7px 9px 8px !important;
    left: 0 !important;

    svg {
        fill: $brand-main !important;
        height: 20px !important;
        width: 20px !important;
    }
}

.gsst_a {
    padding: 9px 0 0 11px !important;
}

.sc-control-cse {
    padding: ($gs-baseline / 4) 0 !important;
}

.gsib_b,
.gsc-search-button-v2 {
    border: 0 !important;
    box-sizing: border-box !important;
    height: 42px !important;
    width: 42px !important;
    margin: 0 !important;
    background-color: transparent !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
}

.gsc-input-box {
    border: 0 !important;
}

.gsib_a {
    border: 0 !important;
    box-shadow: none !important;

    .gsc-input {
        padding: 0 !important;
        margin: 0 !important;
        box-sizing: border-box !important;
        background-position-x: 40px !important;
        // Ensures 'Custom Search' text is after Google logo
        text-indent: 90px !important;
        border: 1px solid $brightness-86 !important;
        border-radius: 999px !important;
        height: 42px !important;
        box-shadow: none !important;
        width: 500px !important;

        &:hover,
        &:focus {
            outline: 0;
        }

        &:focus {
            text-indent: 42px !important;
        }

        &:not(:placeholder-shown) {
            text-indent: 42px !important;
        }
    }
}

.gstl_50, .gssb_c {
    z-index: $zindex-main-menu + 1 !important;
}

.gsc-completion-container,
.gsc-input {
    font-family: $f-sans-serif-text;
    padding: 0 !important;
    margin: 0 !important
}
